<template>
    <Toast />
    <div class="p-shadow p-bg-white p-container" style="width:100%; border-radius:24px !important; padding: 12px !important;">
        <!-- <h3>PERFIL</h3> -->
        <div class="p-grid p-p-0 p-m-0">
            <div class="p-col-12 p-sm-5 p-md-3 p-py-4" style="background-color: rgb(38, 54, 128); color:white; border-radius:12px !important;">
                <div class="p-d-flex p-ai-center p-jc-center p-flex-column">
                    <div class="p-d-flex p-ai-center p-jc-center p-iniciales">
                        <span class="p-text-uppercase" style="letter-spacing: 1pt; color:#4c4c4c;">{{iniciales}}</span>
                    </div>
                    <div class="p-mt-2">
                        <span class="p-text-capitalize" style="font-size:15pt; letter-spacing: 1pt;">{{nombre_apellido}}</span>
                    </div>
                    <div class="p-mb-2">
                        <span title="Carnet" class="p-text-uppercase" style="font-size:11pt; letter-spacing: 1pt;">{{perfil.carnet}}</span>
                    </div>
                    <div>
                        <small class="" style="color:#c1c1c1;">Cuenta {{tipo_cuenta}}</small>
                    </div>
                    <div>
                        <small class="" style="color:#c1c1c1;">Miembro desde {{perfil.registro}}</small>
                    </div>
                </div>
                <div class="p-px-6">
                    <Divider />
                </div>
                <div class="p-d-flex p-ai-center p-flex-column">
                    <Button v-if="perfil.cuenta == 'externo'" class="p-button-text p-button-white hide" label="Actualizar cuenta" @click="modal_cuenta = true"></Button>
                    <Button class="p-button-text p-button-white" label="Cambiar contraseña" @click="modal_clave = true"></Button>
                    <Button class="p-button-text p-button-white" label="Cerrar sesión" @click="logout"></Button>
                </div>
            </div>
            <div class="p-col-12 p-sm-6 p-md-8 p-ml-4 p-mt-3">
                <div class="p-mb-5 p-d-flex p-flex-column">
                    <small class="text-muted">Nombre completo</small>
                    <span class="p-text-capitalize" style="font-size:12pt;">{{perfil.nombres}} {{perfil.apellidos}}</span>
                </div>
                <div class="p-mb-5 p-d-flex p-flex-column">
                    <small class="text-muted">Correo electrónico</small>
                    <span class="" style="font-size:12pt;">{{perfil.correo == "" ? '--' : perfil.correo}}</span>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-py-0 p-pt-3-sm">
                        <div class="p-mb-5 p-d-flex p-flex-column">
                            <small class="text-muted">Teléfono</small>
                            <span class="" style="font-size:12pt;">{{perfil.telefono == "" ? '--' : perfil.telefono}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-py-0">
                        <div class="p-mb-5 p-d-flex p-flex-column">
                            <small class="text-muted">Celular</small>
                            <span class="" style="font-size:12pt;">{{perfil.celular == "" ? '--' : perfil.celular}}</span>
                        </div>
                    </div>
                </div>
                <div class="p-mb-5 p-d-flex p-flex-column">
                    <small class="text-muted">Dirección</small>
                    <span class="p-uc-first" style="font-size:12pt;">{{perfil.direccion == "" ? '--' : perfil.direccion}}</span>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-py-0 p-pt-3-sm">
                        <div class="p-mb-5 p-d-flex p-flex-column">
                            <small class="text-muted">Municipio</small>
                            <span class="p-text-capitalize" style="font-size:12pt;">{{perfil.municipio == "" ? '--' : perfil.municipio}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-py-0">
                        <div class="p-mb-5 p-d-flex p-flex-column">
                            <small class="text-muted">Departamento</small>
                            <span class="p-text-capitalize" style="font-size:12pt;">{{perfil.departamento == "" ? '--' : perfil.departamento}}</span>
                        </div>
                    </div>
                </div>
                <div class="p-mb-5 p-d-flex p-flex-column">
                    <small class="text-muted">Género</small>
                    <span class="p-text-capitalize" style="font-size:12pt;">{{perfil.genero == "" ? '--' : perfil.genero}}</span>
                </div>
                <div class="p-mb-5 p-d-flex p-flex-column">
                    <small class="text-muted">Nacimiento</small>
                    <span class="" style="font-size:12pt;">{{perfil.nacimiento == "" ? '--' : perfil.nacimiento}}</span>
                </div>
                <div class="p-mb-5 p-d-flex p-flex-column">
                    <small class="text-muted">Estado civil</small>
                    <span style="font-size:12pt;">{{perfil.estado_civil == "" ? '--' : perfil.estado_civil}}</span>
                </div>
                <div class="p-mt-4 p-d-flex p-ai-center">
                    <Button class="p-button-ugb" @click="actualizar_informacion" label="Actualizar información"></Button>
                </div>
            </div>
        </div>
    </div>

    <Dialog header="ACTUALIZAR CUENTA" :position="'top'" v-model:visible="modal_cuenta" :breakpoints="{'1350px' : '60vw', '960px': '75vw', '640px': '100vw'}" :style="{width: '30vw'}" :draggable="false" closeOnEscape="true" :modal="true">
        <div class="p-col p-py-0">
            <p>Si eres estudiante activo o egresado de la universidad te invitamos a actualizar tu cuenta estandar a cuenta de estudiante.</p>
            <div class="p-px-3 p-pb-2">
                <div class="p-fluid p-mb-2 p-grid p-d-flex p-ai-center">
                    <div class="p-col-3">
                        <label for="carnet">Carnet:</label>
                    </div>
                    <div class="p-col-9">
                        <InputText id="carnet" v-model="form_cuenta.usuario" placeholder="Escriba su código de carnet" />
                        <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid_clave.actual }">Debe ingresar el carnet.</small>
                    </div>
                </div>
                <div class="p-fluid p-mb-2 p-grid p-d-flex p-ai-center">
                    <div class="p-col-3">
                        <label for="">Clave:</label>
                    </div>
                    <div class="p-col-9">
                        <Password :feedback="false" toggleMask v-model="form_cuenta.clave" placeholder="Escriba su contraseña" />
                        <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid_clave.nueva }">Debe ingresas su contraseña.</small>
                    </div>
                </div>
                <div class="p-col p-p-0 p-d-flex p-jc-end p-ai-center p-mt-3">
                    <Button style="width:200px;" @click="actualizar_cuenta" :loading="actualizar_is_loading" label="Actualizar cuenta"></Button>
                </div>
            </div>
        </div>
    </Dialog>
    <Dialog header="ACTUALIZAR INFORMACIÓN" :position="'top'" v-model:visible="modal_informacion" :breakpoints="{'1350px' : '60vw', '960px': '75vw', '640px': '100vw'}" :style="{width: '40vw'}" :draggable="false" closeOnEscape="true" :modal="true">
        <div class="p-fluid p-px-2">

            <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
                <div class="p-col-3">
                    <label for="">Dirección:</label>
                </div>
                <div class="p-col-9">
                    <InputText id="direccion" v-model="form_actualizar.direccion" placeholder="Dirección de residencia" />
                    <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid.direccion }">Debe ingresar su dirección.</small>
                </div>
            </div>

            <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
                <div class="p-col-3">
                    <label for="">Departamento:</label>
                </div>
                <div class="p-col-9">
                    <Dropdown placeholder="Seleccione" v-model="form_actualizar.departamento" id="departamento" @change="obtener_municipios" :options="lista_departamentos" optionLabel="departamento" optionValue="codigo" :loading="departamento_is_loading"/>
                    <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid.departamento }">Debe seleccionar el departamento.</small>
                </div>
            </div>

            <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
                <div class="p-col-3">
                    <label for="">Municipio:</label>
                </div>
                <div class="p-col-9">
                    <Dropdown placeholder="Seleccione" v-model="form_actualizar.municipio" id="municipio" :options="lista_municipios" optionLabel="municipio" optionValue="codigo" :loading="municipio_is_loading" emptyMessage="Seleccione departamento"/>
                    <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid.municipio }">Debe seleccionar el municipio.</small>
                </div>
            </div>

            <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
                <div class="p-col-3">
                    <label for="">Teléfono:</label>
                </div>
                <div class="p-col-9">
                    <InputMask v-model="form_actualizar.telefono" id="telefono" mask="9999-9999" placeholder="6000-0000"/>
                </div>
            </div>

            <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
                <div class="p-col-3">
                    <label for="">Celular:</label>
                </div>
                <div class="p-col-9">
                    <InputMask v-model="form_actualizar.celular" id="celular" mask="9999-9999" placeholder="2664-0000" />
                </div>
            </div>

            <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
                <div class="p-col-3">
                    <label for="">Estado civil:</label>
                </div>
                <div class="p-col-9">
                    <Dropdown v-model="form_actualizar.estado_civil" :loading="estado_civil_is_loading" :options="lista_estado_civil" optionLabel="nombre" optionValue="codigo" placeholder="Seleccione" />
                    <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid.estado_civil }">Debe seleccionar su estado civil.</small>
                </div>
            </div>
        </div>
        <div class="p-col p-pt-2">
            <div class="p-d-flex p-ai-center p-jc-end">
                <Button icon="pi pi-save" @click="actualizar_datos()" :loading="update_loading" label="Actualizar datos"></Button>
            </div>
        </div>
    </Dialog>
    <Dialog v-model:visible="modal_clave" :position="'top'" @hide="hide_clave_modal" header="ACTUALIZAR CONTRASEÑA" :style="{width: '30vw'}" :breakpoints="{'1350px' : '60vw', '960px': '75vw', '640px': '100vw'}" :draggable="false" closeOnEscape="true" :modal="true">
        <!--
        <div v-if="perfil.cuenta == 'empleado'" style="font-size:11pt !important;">
            <CambiarClaveEmpleado></CambiarClaveEmpleado>
        </div>
        <div v-else-if="perfil.cuenta == 'estudiante'" style="font-size:11pt !important;">
            <CambiarClaveEstudiante></CambiarClaveEstudiante>
        </div>
        -->
        <div>
            <div class="p-px-3 p-pb-2">
                <div class="p-mb-2">
                    <small class="text-muted">Todos los campos son requeridos.</small>
                </div>
                <form @submit.prevent="actualizar_clave">
                    <div class="p-fluid">
                        <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
                            <div class="p-col-3">
                                <label for="">Clave actual:</label>
                            </div>
                            <div class="p-col-9">
                                <Password :feedback="false" ref="actual" v-model="form_clave.actual" placeholder="Escriba contraseña actual" />
                                <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid_clave.actual }">Escriba la contraseña actual.</small>
                            </div>
                        </div>
                        <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
                            <div class="p-col-3">
                                <label for="">Nueva clave:</label>
                            </div>
                            <div class="p-col-9">
                                <Password :feedback="false" toggleMask v-model="form_clave.nueva" placeholder="escriba nueva contraseña" />
                                <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid_clave.nueva }">Escriba la nueva contraseña.</small>
                            </div>
                        </div>
                        <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
                            <div class="p-col-3">
                                <label for="">Confirmar:</label>
                            </div>
                            <div class="p-col-9">
                                <Password :feedback="false" toggleMask v-model="form_clave.confirmar" placeholder="confirme la nueva contraseña" />
                                <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid_clave.confirmar }">Confirme la nueva contraseña.</small>
                                <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid_clave.coincide }">Contraseñas no coinciden.</small>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-flex p-jc-end p-py-0 p-mt-3">
                        <Button type="submit" style="width:170px;" :loading="loading_clave" @click="actualizar_clave" label="Cambiar contraseña"></Button>
                    </div>
                </form>
            </div>
        </div>
    </Dialog>
</template>

<style scoped>
    .p-pt-3-sm{ padding-top: 0; }
    .p-button.p-button-text.p-button-white{ color:#c3c3c3 !important; }
    .p-d-xs-none{ display: inline-flex; }
    .p-d-xs-block{ display: none !important; }
    @media (max-width: 576px) {
        .p-pt-3-sm{ padding-top: 10px !important; }
        .p-d-xs-none{ display: none !important; }
        .p-d-xs-block{ display: inline-flex !important; }
        .p-mb-5{ margin-bottom: 15px !important; }
    }
    .p-iniciales{
        font-size:20pt;
        font-weight: 500;
        width:110px; 
        height:110px; 
        background: #d9d9d9;
        border:5px solid #f1f1f1; 
        border-radius:50%;
    }
</style>

<script>
//import CambiarClaveEstudiante from '@/components/perfil/CambiarClaveEstudiante.vue';
//import CambiarClaveEmpleado from '@/components/perfil/CambiarClaveEmpleado.vue';
export default{
    components : {
        //'CambiarClaveEstudiante' : CambiarClaveEstudiante,
        //'CambiarClaveEmpleado' : CambiarClaveEmpleado,
    },
    data (){
        return {
            is_loading : false,
            loading_clave : false,
            update_loading : false,
            modal_clave : false,
            modal_cuenta : false,
            modal_informacion : false,            
            form_actualizar : {
                direccion : '',
                departamento : '',
                municipio : '',
                telefono : '',
                celular : '',
                estado_civil : '',
            },
            form_cuenta : {
                usuario :  '',
                clave : '',
            },
            form_clave:{
                actual : '',
                nueva : '',
                confirmar : '',
            },
            perfil : {
                nombres : '',
                apellidos : '',
                correo : '',
                direccion : '',
                genero : '',
                telefono : '',
                celular : '',
                nacimiento : '',
                estado_civil : '',
                registro : '',
                cuenta : '',
                departamento : '',
                municipio : '',
                codigo_departamento : '',
                codigo_municipio : '',
                codigo_estado_civil : '',
            },
            isvalid : {
                direccion : true,
                departamento : true,
                municipio : true,
                estado_civil : true,
            }, 
            isvalid_clave : {
                actual : true,
                nueva : true,
                confirmar : true,
                coincide : true,
            },
            lista_departamentos : [],
            lista_municipios : [],
            lista_estado_civil : [],
            departamento_is_loading : false,
            municipio_is_loading : false,
            estado_civil_is_loading : false,
            actualizar_is_loading : false,
        }
    },
    methods : {
        async actualizar_cuenta(){
            this.actualizar_is_loading = true
			await this.axios.post("/Perfil/ActualizarCuenta", this.form_cuenta)
            .then(() => {  })
            .catch(()=>{})
			.then(() => { this.actualizar_is_loading = false; })
        },
        async actualizar_clave(){
            let actual = this.form_clave.actual;
            let nueva = this.form_clave.nueva;
            let confirmar = this.form_clave.confirmar;
            this.isvalid_clave.actual = actual.length > 0 ? true : false;
            this.isvalid_clave.nueva = nueva.length > 0 ? true : false;
            this.isvalid_clave.confirmar = confirmar.length > 0 ? true : false;
            this.isvalid_clave.coincide = (confirmar.length == 0 ? true : (nueva == confirmar) ? true : false);
            if((Object.values(this.isvalid_clave).indexOf(false) >= 0)) return false;
            let p = { clave_actual : actual, clave_nueva : nueva }
            this.loading_clave = true;
            await this.axios.post("/Perfil/Cambiar", p)
            .then(r => { 
                if(r.data == 1){
                    this.$toast.add({severity:'success', summary: 'Realizado', detail:'Información actualizada', life: 3000});
                    this.modal_clave = false;
                }else if(r.data == 2){
                    this.$toast.add({severity:'error', summary: 'Error', detail:'La contraseña actual es incorrecta', life: 3000});
                    this.$refs.actual.$el.children[0].focus();
                    this.clave.actual = '';
                }else{
                    this.$toast.add({severity:'error', summary: 'Aviso', detail:'Ha ocurrido un error, por favor vuelta a intentarlo', life: 3000});
                }
            })
            .catch(()=>{ this.$toast.add({severity:'error', summary: 'Aviso', detail:'Ha ocurrido un error, por favor vuelta a intentarlo', life: 3000}); })
			.then(() => { this.loading_clave = false; })
        },
        hide_clave_modal(){
            for (let item in this.isvalid_clave) { this.isvalid_clave[item] = true; }
            for (let item in this.form_clave) { this.form_clave[item] = ''; }
        },
        async actualizar_datos(){
			this.update_loading = true;
            this.form_actualizar.departamento = this.form_actualizar.departamento == "" ? null : this.form_actualizar.departamento;
            this.form_actualizar.municipio = this.form_actualizar.municipio == "" ? null : this.form_actualizar.municipio;
			await this.axios.post("/Perfil/Update", this.form_actualizar)
            .then(r => { if(r.data == 1){
                this.$toast.add({severity:'success', summary: 'Realizado', detail:'Información actualizada', life: 3000});
                this.modal_informacion = false;
                this.traer_perfil();
            }
            })
            .catch(()=>{ this.$toast.add({severity:'error', summary: 'Aviso', detail:'Ha ocurrido un error, por favor vuelta a intentarlo', life: 3000}); })
			.then(() => { this.update_loading = false; })
		},
        actualizar_informacion(){
            let p = this.perfil;
            this.form_actualizar.direccion = p.direccion;
            this.form_actualizar.telefono = p.telefono;
            this.form_actualizar.celular = p.celular;
            this.form_actualizar.estado_civil = p.codigo_estado_civil;
            if(p.codigo_municipio != null){
                this.form_actualizar.departamento = p.codigo_departamento;
                this.form_actualizar.municipio = p.codigo_municipio;
                this.obtener_municipios();
            }
            this.modal_informacion = true;
            if(this.lista_departamentos.length == 0) this.obtener_departamentos();
            if(this.lista_estado_civil.length == 0) this.obtener_estadosCiviles();
        },
        async traer_perfil(){
            this.is_loading = true;
            await this.axios.get("/Perfil")
            .then(r => { 
                this.perfil.nombres = r.data[0].nombres;
                this.perfil.apellidos = r.data[0].apellidos;
                this.perfil.carnet = r.data[0].carnet;
                this.perfil.correo = r.data[0].correo;
                this.perfil.direccion = r.data[0].direccion;
                this.perfil.genero = r.data[0].genero;
                this.perfil.telefono = r.data[0].telefono;
                this.perfil.celular = r.data[0].celular;
                this.perfil.nacimiento = r.data[0].nacimiento;
                this.perfil.estado_civil = r.data[0].estado_civil;
                this.perfil.registro = r.data[0].registro;
                this.perfil.cuenta = r.data[0].tipo_cuenta;
                this.perfil.departamento = r.data[0].departamento;
                this.perfil.municipio = r.data[0].municipio;
                this.perfil.codigo_departamento = r.data[0].codigo_departamento;
                this.perfil.codigo_municipio = r.data[0].codigo_municipio;
                this.perfil.codigo_estado_civil = r.data[0].codigo_estado_civil;
            })
            .catch(() => {})
			.then(() => { this.is_loading = false });
        },
        async obtener_departamentos(){
			this.departamento_is_loading = true
			await this.axios.get("/Registro")
            .then(r => { this.lista_departamentos = r.data })
            .catch(()=>{})
			.then(() => { this.departamento_is_loading = false })
		},
		async obtener_municipios(){
			this.municipio_is_loading = true
			await this.axios.get("/Registro/Municipios/"+this.form_actualizar.departamento)
            .then(r => { this.lista_municipios = r.data })
            .catch(()=>{})
			.then(() => { this.municipio_is_loading = false })
		},
		async obtener_estadosCiviles(){
			this.estado_civil_is_loading = true
			await this.axios.get("/Registro/EstadosCiviles")
            .then(r => { this.lista_estado_civil = r.data })
            .catch(()=>{})
			.then(() => { this.estado_civil_is_loading = false })
		},
        logout(){
			this.$store.commit("setLogged", 0);
		},
    },
    mounted (){
        if(this.$store.state.is_logged == 0) this.$router.push("/");
        this.traer_perfil();
    },
    computed : {
        name : function(){return this.$router.currentRoute.name},
        iniciales : function(){return this.perfil.nombres[0] + this.perfil.apellidos[0]},
        nombre_apellido : function(){
            let p = this.perfil;
            let n = p.nombres.split(" ")[0].trim();
            let a = p.apellidos.split(" ")[0].trim();
            return n + " " + a;
        },
        tipo_cuenta : function(){
            let p = this.perfil.cuenta;
            return p == 'externo' ? 'estandar' : p == 'estudiante' ? 'de estudiante' : 'de empleado';
        },
        
    },
}
</script>
